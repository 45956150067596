// import { useGLTF } from '@react-three/drei'
// import { useFrame } from '@react-three/fiber'
// import { useRef } from 'react'
// import { MeshStandardMaterial } from 'three'

// export default function Logo() {
//     const ref = useRef<THREE.Mesh>(null!)
//     const gltf = useGLTF('logo_yl.gltf')
//     const redMaterial = new MeshStandardMaterial({
//         color : 0xe80a0a
//     })

//     useFrame((state, delta) => (ref.current.rotation.y += 0.01))

//     return (
//         <primitive
//             ref={ref}
//             object={gltf.scene} 
//             scale={0.8}
//             position={[0, 0, 0]} 
//         />
//     )
// }


/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import { MeshStandardMaterial } from 'three'

export default function Logo(props: any) {
    const ref = useRef<THREE.Mesh>(null!)
    const { nodes, materials } = useGLTF('/logo_yl.gltf')
    const redMaterial = new MeshStandardMaterial({
        color: 16777215,
        wireframe: true
    })
    useFrame((state, delta) => {
        // ref.current.rotation.y -= 0.005
    })
    return (
        <group {...props} dispose={null}>
            <mesh
                ref={ref}
                castShadow
                receiveShadow
                // @ts-ignore
                geometry={nodes.Cylinder003.geometry}
                // @ts-ignore
                // material={nodes.Cylinder003.material}
                material={redMaterial}
                rotation={[Math.PI / 2, 1.42, Math.PI]}
                scale={[0.8, 0.2, 0.8]}
            />
        </group>
    )
}

useGLTF.preload('/logo_yl.gltf')

