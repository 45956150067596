import FiberCanvas from './FiberCanvas'
import './index.css'
import styles from './App.module.css'

function App() {
    return (
        <main>
            <div className={styles.mainContainer}>
                <h1>yoannlatzer.com/.fr</h1>
                <h1>under construction</h1>
                <a href={'https://calendly.com/yoannmelatzer'} target="_blank">
                    <button>book a meeting with me!</button>
                </a>
                <a href="mailto:contact@yoannlatzer.fr">
                    <h2>email me: contact@yoannlatzer.fr</h2>
                </a>
                <a href="https://www.linkedin.com/in/yoann-m-e-latzer-45a51638/" target="_blank">
                    <h2>visit my linkedin</h2>
                </a>
            </div>
            <div className={styles.canvasContainer}>
                <FiberCanvas />
            </div>
        </main>
    )
}

export default App
